import React, { useContext } from 'react';
import { store } from '@components/search.title/store-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import HistoryUserPhrases from './HistoryUserPhrases';
import RalatedTopPhrases from './RalatedTopPhrases';
import Sections from './Sections';
import Items from './Items';
import LinearProgress from '@material-ui/core/LinearProgress';
/**
 * Компонент малой корзины
 * @param param0
 * @returns
 */
var Widget = React.memo(function () {
    var _a = useContext(store), _b = _a.state, focus = _b.focus, type = _b.type, query = _b.query, isAjax = _b.isAjax, elements = _b.elements, dispatch = _a.dispatch;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    if (!focus || type !== 'catalog') {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "newsearch-wrap", style: { display: 'block' } },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-md-4 col-lg-3 col-12 categories-col" },
                    isAjax && isMobile ? React.createElement(LinearProgress, { color: "primary" }) : null,
                    React.createElement(HistoryUserPhrases, null),
                    React.createElement(Sections, null),
                    React.createElement(RalatedTopPhrases, null)),
                React.createElement("div", { className: "col-md-8 col-lg-9 col-12" },
                    !query.length ? 'Начните набирать поисковый запрос' : null,
                    React.createElement(Items, null),
                    query.length && !elements.length && !isAjax
                        ? 'Ничего не найдено. Попробуйте изменить запрос'
                        : null,
                    query.trim().length && elements.length && !isAjax ? (React.createElement("a", { href: "/search/?q=" + query, className: "search-link " + (isAjax ? 'disabled' : '') }, "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0432\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B")) : null)))));
});
export default React.memo(Widget);
