var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { createContext, useReducer } from 'react';
export var initialState = {
    basePrice: 0,
    basePriceFormat: '',
    basketDiscount: '',
    coupons: {
        CATALOG_USER_COUPONS: [],
        VALID_COUPONS: [],
    },
    cartItems: [],
    favItems: [],
    services: [],
    count: 0,
    currency: '',
    price: 0,
    priceFormat: '',
    quantity: 0,
    isInitCartItem: false,
    isAjax: false,
    userId: 0,
    tabActive: 0,
};
var store = createContext({
    state: initialState,
    dispatch: function () { return null; },
});
var Provider = store.Provider;
var reducer = function (state, action) {
    var payload = action === null || action === void 0 ? void 0 : action.payload;
    switch (action.type) {
        case 'setAjax':
            return __assign(__assign({}, state), { isAjax: payload });
        case 'setQunaity':
            var quantity = payload.quantity;
            var itemIndex = state.cartItems.findIndex(function (el) { return el.ID === payload.id; });
            if (itemIndex >= 0) {
                state.cartItems[itemIndex].QUANTITY = quantity;
            }
            return __assign(__assign({}, state), { cartItems: __spreadArray([], __read(state.cartItems), false) });
            break;
        case 'setBasketData':
            return __assign(__assign({}, state), payload);
        case 'setTab':
            return __assign(__assign({}, state), { tabActive: payload });
        default:
            return state;
    }
};
var StateProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useReducer(reducer, initialState), 2), state = _b[0], dispatch = _b[1];
    return React.createElement(Provider, { value: { state: state, dispatch: dispatch } }, children);
};
var refreshOrderList = function () { };
export { store, StateProvider };
