import React, { useContext } from 'react';
import { store } from '@components/search.title/store-context';
/**
 * История поиска посетителя
 * @param param0
 * @returns
 */
var HistoryUserPhrases = React.memo(function () {
    var _a = useContext(store), historyUserPhrases = _a.state.historyUserPhrases, dispatch = _a.dispatch;
    var limit = 5;
    if (!historyUserPhrases.length) {
        return null;
    }
    return (React.createElement("div", { className: "user-searches categories-search" }, historyUserPhrases.slice(0, limit).map(function (el) { return (React.createElement("a", { href: "/search/?q=" + el, key: el }, el)); })));
});
export default React.memo(HistoryUserPhrases);
