var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useState, useCallback, useEffect, useRef, } from 'react';
import { store } from '@components/search.title/store-context';
import debounce from 'lodash/debounce';
import request from '@utils/request';
import axios from 'axios';
import FinderPnatnet from './../FinderPlantnet';
import { getUrlParam } from './utils';
/**
 * Основное поле ввода поискового запроса
 * @param param0
 * @returns
 */
var Search = React.memo(function () {
    var _a = useContext(store), dispatch = _a.dispatch, _b = _a.state, type = _b.type, query = _b.query, focus = _b.focus;
    var formRef = useRef(null);
    var _c = __read(useState(window.location.pathname.includes('sad') ? '/search/sad/' : '/search/'), 2), formAction = _c[0], setFormAction = _c[1];
    useEffect(function () {
        var q = getUrlParam('q');
        if (q === null || q === void 0 ? void 0 : q.length) {
            dispatch({ type: 'setQuery', payload: q });
        }
    }, []);
    var cancelTokenSource = null;
    useEffect(function () {
        return function () {
            if (cancelTokenSource) {
                cancelTokenSource.cancel('stop erquest');
            }
        };
    }, []);
    var handleChange = useCallback(debounce(function (e) {
        if (type !== 'catalog') {
            return;
        }
        var bodyFormData = new FormData();
        bodyFormData.append('q', e.target.value);
        bodyFormData.append('ajax_call', 'y');
        bodyFormData.append('page', '1');
        if (cancelTokenSource) {
            cancelTokenSource.cancel('stop request');
        }
        cancelTokenSource = axios.CancelToken.source();
        dispatch({ type: 'setAjax', payload: true });
        request
            .post('/local/components/dvaslona/search.title/ajax.php', bodyFormData, { cancelToken: cancelTokenSource.token })
            .then(function (response) {
            dispatch({ type: 'setAjax', payload: false });
            dispatch({
                type: 'setData',
                payload: {
                    ralatedTopPhrases: response.data.ralatedTopPhrases,
                    sections: response.data.SECTIONS,
                    elements: response.data.elements,
                    historyUserPhrases: response.data.historyUserPhrases,
                },
            });
            try {
                // @ts-ignore: Unreachable code error
                dataLayer.push({
                    event: 'eventToGA',
                    eventCategory: 'SearchTitle',
                    eventAction: 'Предпросмотр результатов поиска',
                    eventLabel: e.target.value,
                });
            }
            catch (e) {
                console.error(e);
            }
        })
            .catch(function (e) {
            if (e.message !== 'stop request') {
                dispatch({ type: 'setAjax', payload: false });
            }
        })
            .finally(function () {
            // dispatch({ type: 'setAjax', payload: false });
        });
    }, 400), []);
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { ref: formRef, action: formAction },
            React.createElement("input", { name: "q", value: query, onFocus: function (e) {
                    dispatch({ type: 'setFocus', payload: true });
                }, onBlur: function (e) {
                    setTimeout(function () {
                        //dispatch({ type: 'setFocus', payload: false });
                    }, 500);
                }, onChange: function (e) {
                    dispatch({ type: 'setFocus', payload: true });
                    dispatch({ type: 'setQuery', payload: e.target.value });
                    handleChange(e);
                }, className: "form-control header__search search-input", placeholder: "\u041F\u043E\u0438\u0441\u043A", id: "title-search-input", type: "text", size: 40, maxLength: 50, autoComplete: "off", required: true }),
            (focus || window.location.href.includes('/search/')) && (React.createElement("select", { onKeyDown: function (e) {
                    var _a;
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        dispatch({ type: 'setFocus', payload: false });
                        (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.submit();
                    }
                }, onChange: function (e) {
                    switch (e.currentTarget.value) {
                        case 'catalog':
                        default:
                            setFormAction('/search/');
                            break;
                        case 'forum':
                            setFormAction('/search/sad/');
                            break;
                    }
                    dispatch({ type: 'setType', payload: e.currentTarget.value });
                }, id: "searchSelect" },
                React.createElement("option", { value: "catalog", selected: formAction == '/search/' }, "\u0420\u0430\u0441\u0442\u0435\u043D\u0438\u044F"),
                React.createElement("option", { value: "forum", selected: formAction == '/search/sad/' }, "\u0422\u043E\u0432\u0430\u0440\u044B \u0434\u043B\u044F \u0441\u0430\u0434\u0430"))),
            React.createElement(FinderPnatnet, null),
            React.createElement("button", { name: "s", type: "submit", className: "btn btn-link search-btn color-orange" },
                React.createElement("i", { className: "fa fa-search" })))));
});
export default Search;
