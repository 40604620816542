import axios from 'axios';
// TS https://github.com/axios/axios/issues/1510
axios.defaults.withCredentials = true;
export var REQUEST_HOST = window.location.host;
var request = axios.create({
    withCredentials: false,
    baseURL: "//" + REQUEST_HOST,
    maxRedirects: 3,
    headers: {
    // 'Access-Control-Allow-Origin': '*',
    // Accept: 'application/json',
    },
    //...(process.env.NODE_ENV === 'production' ? {} : auth),
});
export default request;
