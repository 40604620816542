var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Suspense, lazy, useContext, useRef, useEffect, useState, useCallback, } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { StateProvider } from './store-context';
import { store } from '@components/sale.basket.basket.line/store-context';
import { getBasket } from './utils';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
var Tabs = lazy(function () { return import('./Tabs'); });
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
var drawerWidth = 420;
var useStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        drawerPaper: (_a = {
                width: drawerWidth,
                background: "#22292e",
                color: '#fff'
            },
            _a[theme.breakpoints.down('sm')] = {
                width: "calc(100% - " + 10 + "px)",
                flexShrink: 0,
            },
            _a),
        snackBarSuccess: { fontSize: '16px' },
        snackBarError: { fontSize: '16px' },
        snackBarWarning: { fontSize: '16px' },
        snackBarInfo: { fontSize: '16px' },
    });
});
/**
 * Компонент малой корзины
 * @param param0
 * @returns
 */
var BasketLine = React.memo(function (_a) {
    var numProducts = _a.numProducts, totalSumm = _a.totalSumm, userId = _a.userId;
    var _b = useContext(store), _c = _b.state, basePriceFormat = _c.basePriceFormat, priceFormat = _c.priceFormat, favItems = _c.favItems, count = _c.count, isAjax = _c.isAjax, dispatch = _b.dispatch;
    var _d = __read(useState(false), 2), open = _d[0], setOpen = _d[1];
    var classes = useStyles();
    var notistackRef = useRef();
    var onClickDismiss = useCallback(function (key) { return function () {
        notistackRef.current.closeSnackbar(key);
    }; }, []);
    // Переключение статуса видимости корзины
    var toggleDrawer = function (open) { return function (event) {
        if (event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' ||
                event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    }; };
    // Метод обновляет корзину, для внешнего использования
    var updateCart = useCallback(function () {
        getBasket().then(function (response) {
            dispatch({
                type: 'setBasketData',
                payload: __assign(__assign({}, response.data.data), { isInitCartItem: true }),
            });
        });
    }, []);
    useEffect(function () {
        try {
            $('#mobileQuantity').text(count);
            if (!count) {
                $('#mobileQuantity').addClass('hidden');
            }
            else {
                $('#mobileQuantity').removeClass('hidden');
            }
        }
        catch (error) {
            console.error(error);
        }
    }, [numProducts, count]);
    // Инициализация
    useEffect(function () {
        // Методы для внешнего использования
        window.smallBasketLine.setOpen = function (status, tab) {
            if (tab === void 0) { tab = 0; }
            setOpen(status);
            dispatch({
                type: 'setTab',
                payload: tab,
            });
        };
        window.smallBasketLine.update = function () { return updateCart(); };
        // Краткие данные корзины доступны сразу
        dispatch({
            type: 'setBasketData',
            payload: { basePriceFormat: totalSumm, count: numProducts, userId: userId },
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { cursor: 'pointer' } },
            React.createElement("a", { onClick: toggleDrawer(!open), className: "btn btn-block btn-lg clearfix cart-trigger" },
                React.createElement("i", { className: "fa fa-shopping-cart" }),
                count ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { id: "cart-total", className: "d-none d-md-inline" }, count),
                    React.createElement("span", { className: "d-md-none d-lg-inline" }, basePriceFormat))) : (React.createElement("span", { id: "cart-empty" }, "\u041A\u043E\u0440\u0437\u0438\u043D\u0430")))),
        React.createElement(SwipeableDrawer, { anchor: "right", open: open, onOpen: toggleDrawer(true), onClose: toggleDrawer(false), classes: {
                paper: classes.drawerPaper,
            }, ModalProps: {
                keepMounted: true, // Better open performance on mobile.
            } },
            React.createElement(SnackbarProvider, { ref: notistackRef, preventDuplicate: true, hideIconVariant: true, action: function (key) { return (React.createElement(IconButton, { onClick: onClickDismiss(key), "aria-label": "delete" },
                    React.createElement(CloseIcon, { fontSize: "small" }))); }, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, classes: {
                    variantSuccess: classes.snackBarSuccess,
                    variantError: classes.snackBarError,
                    variantWarning: classes.snackBarWarning,
                    variantInfo: classes.snackBarInfo,
                } },
                React.createElement(Suspense, { fallback: React.createElement("div", null, "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u043A\u043E\u0440\u0437\u0438\u043D\u044B...") }, open ? React.createElement(Tabs, { setOpen: setOpen }) : null)))));
});
var theme = createTheme({
    palette: {
        primary: {
            main: '#dd0504',
        },
        secondary: {
            main: '#DF430A',
        },
        error: {
            main: '#fff',
        },
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '16px',
            },
        },
    },
});
var SmallBasket = /** @class */ (function () {
    function SmallBasket() {
        // Монтируем компонент в DOM
        this.initComponent = function (idElement) {
            var container = document.getElementById(idElement);
            ReactDOM.render(React.createElement(ThemeProvider, { theme: theme },
                React.createElement(StateProvider, null,
                    React.createElement(BasketLine, { numProducts: parseInt(String(container === null || container === void 0 ? void 0 : container.dataset.numProducts)), totalSumm: String(container === null || container === void 0 ? void 0 : container.dataset.totalSumm), userId: parseInt(String(container === null || container === void 0 ? void 0 : container.dataset.userId)) }))), container);
        };
        // Метод для внешнего управление состояним видмости малой корзины.
        // Перегружается после монтирования компонента
        this.setOpen = function (status, tab) { };
        // Заставить корзину обновить состояние
        this.update = function () { };
    }
    return SmallBasket;
}());
var smallBasketLine = new SmallBasket();
// Внешний API для управления состоянием корзины из внешнего мира
window['smallBasketLine'] = smallBasketLine;
// Монтируем компонент
// smallBasketLine.initComponent('appBasketLine');
