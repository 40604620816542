var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useState, Suspense, lazy, } from 'react';
import { store } from '@components/search.title/store-context';
import { makeStyles, createStyles } from '@material-ui/core/styles';
var DialogWindow = lazy(function () { return import('./Dialog'); });
var useStyles = makeStyles(function (theme) {
    return createStyles({
        button: {
            position: 'absolute',
            right: "145px",
            marginTop: "5px",
            cursor: 'pointer',
        },
    });
});
var Index = React.memo(function () {
    var _a = useContext(store), finderPlantnet = _a.state.finderPlantnet, dispatch = _a.dispatch;
    var _b = __read(useState(false), 2), init = _b[0], setInit = _b[1];
    var open = finderPlantnet.open;
    var classes = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { onClick: function () {
                setInit(true);
                dispatch({ type: 'setOpenFinderPlantnet', payload: !open });
            }, className: classes.button, style: { zIndex: open ? 0 : 999 } },
            React.createElement("img", { src: "/local/templates/lafa.ru/assets/img/photo-icon.png", className: "loading", "data-ll-status": "loading" })),
        init || open ? (React.createElement(Suspense, { fallback: React.createElement(React.Fragment, null) },
            React.createElement(DialogWindow, null))) : null));
});
export default Index;
