import React, { useContext } from 'react';
import { store } from '@components/search.title/store-context';
/**
 * Разделы сайта подоходящие под поисковую фразу
 * @param param0
 * @returns
 */
var Sections = React.memo(function () {
    var _a = useContext(store), sections = _a.state.sections, dispatch = _a.dispatch;
    if (!sections.length) {
        return null;
    }
    return (React.createElement("div", { className: "categories-search" },
        React.createElement("span", { className: "category-ttl" }, "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438"),
        sections.map(function (el) { return (React.createElement("a", { href: "" + el.SECTION_PAGE_URL, key: el.ID }, el.NAME)); })));
});
export default React.memo(Sections);
